.panel.info {
  padding: 0;
  margin-top: 30px;
}

.panel-header {
  background: #f9fbfc;
  padding: 30px;
}

.panel-header h2 {
  font-size: 24px;
  display: inline-block;
}

.panelbody {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}

.panelbody .col1 {
  flex: 0 0 29%;
  padding: 0 15px;
}

.panelbody .col2 {
  flex: 0 0 40%;
  padding: 0 15px;
}
.panelbody .col1 ul {
  padding: 0;
  list-style-type: none;
}

.panelbody .col1 li {
  font-size: 20px;
  display: flex;
  margin-bottom: 17px;
}

.panelbody .col1 li span {
  display: inline-block;
  text-align: right;
  color: #969696;
  margin-right: 12px;
  flex: 0 0 50%;
}
.panelbody textarea.form-control {
  min-height: 109px;
}
.panelbody a.btn {
  text-decoration: none;
  box-shadow: none;
}
.panel-header .pull-right {
  margin-top: -8px;
}

.panel-header .pull-right a.btn {
  box-shadow: none;
  border-radius: 57px;
  padding: 8px 20px;
  font-size: 18px;
}
.full_width {
  margin-top: 30px;
  width: 100%;
}

.table-information tr th {
  padding: 5px;
  background: none;
  border: none;
}

.table-information tr td {
  border: none;
  padding: 7px;
}

.table-information tr th {
  padding: 5px 30px;
  background: none;
  border: none;
  font-size: 20px;
}

.table-information tr td {
  border: none;
  padding: 5px 30px;
  font-size: 20px;
}
.table-information tr td:nth-child(2),
.table-information tr th:nth-child(2) {
  width: 60%;
}
.table-information tr td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.table-information {
  width: 80%;
}
.full_width h3 {
  font-size: 26px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 25px;
  font-weight: 600;
}
.pull-right span {
  padding: 18px;
  cursor: pointer;
}
a.btn.link {
  color: #5595ff;
  font-size: 20px;
}

.panel.center.col {
  max-width: 1170px;
  margin: 0 auto;
}
.panel.col form {
  display: flex;
  flex-wrap: wrap;
}
.panel.col form .form-group {
  flex: 0 0 48%;
  margin-right: 20px;
}
.panel.col form .form-group:nth-child(2n) {
  margin-right: 0;
}

.panel.col form .form-group.full {
  flex: 0 0 100%;
}
.panel.center.col .btn {
  display: block;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .full_width h3 {
    font-size: 20px;
  }
  .panelbody .col1 li {
    font-size: 16px;
  }
  .panel-header {
    padding: 20px;
  }
  .panel-header h2 {
    font-size: 20px;
    display: inline-block;
  }
  .panelbody .col1 {
    flex: 0 0 100%;
  }
  .panelbody .col1 li span {
    text-align: left;
  }
  .panelbody .col2 {
    flex: 0 0 100%;
  }
  .panelbody {
    padding: 20px 20px;
  }
  .panel-header .pull-right a.btn {
    font-size: 16px;
  }
  .table-information {
    width: 100%;
  }
  .table-information tr td {
    border: none;
    padding: 5px 16px;
    font-size: 16px;
  }
  .table-information tr th {
    padding: 5px 16px;
    background: none;
    border: none;
    font-size: 16px;
  }
  .table-information tr td:nth-child(2),
  .table-information tr th:nth-child(2) {
    width: 46%;
  }
  .icons_list a {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .panel.center.event form {
    display: block;
  }
  .panel.center.event form .form-group {
    margin: 0px 0px 15px 0px;
  }
}
