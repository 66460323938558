.table {
    tr {
        td {
            padding: 15px 20px;
            label {
                font-size: 16px;
            }

            .form-check-input {
                margin: 0 5px 0 0;
            }
        }
    }

    tbody, td, tfoot, th, thead, tr {
        border-width: 1px;
    }

}


