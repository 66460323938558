.pvtUi {
  color: #2a3f5f;
  font-family: Verdana;
  border-collapse: collapse;
  select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }
  td.pvtOutput {
    vertical-align: top;
  }
}
table.pvtTable {
  font-size: 8pt;
  text-align: left;
  border-collapse: collapse;
  margin-top: 3px;
  margin-left: 0px;
  font-family: Verdana;
  background-color: #fff;
  thead {
    tr {
      th {
        background-color: #e8f3f7;
        border: 2px solid #fff;
        font-size: 8pt;
        padding: 5px;
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: #e8f3f7;
        border: 1px solid #fff;
        font-size: 8pt;
        padding: 5px;
      }
      td {
        color: #2a3f5f;
        padding: 5px;
        background-color: #fff;
        border: 1px solid #e8f3f7;
        vertical-align: top;
        text-align: right;
      }
      .second-span {
        background: #d4e8f0;
        border-color: #fff;
        width: 141px;
      }
    }
  }
  .pvtColLabel {
    text-align: center;
    background: #d4e8f0;
  }
  .pvtTotalLabel {
    text-align: right;
  }
}
.pvtTotal {
  font-weight: bold;
}
.pvtGrandTotal {
  font-weight: bold;
}
.pvtRowOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
  text-decoration: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.pvtColOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
  text-decoration: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.pvtAxisContainer {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  li {
    list-style-type: none;
    cursor: move;
    span.pvtAttr {
      -webkit-text-size-adjust: 100%;
      background: #f3f6fa;
      border: 1px solid #c8d4e3;
      padding: 2px 5px;
      white-space: nowrap;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -ms-user-select: none;
      img {
        width: 10px;
      }
    }
  }
  li:hover span.pvtAttr:hover {
    background: #269dcc;
    color: #fff;
    border: 1px solid #269dcc;
  }
  li.pvtPlaceholder {
    -webkit-border-radius: 5px;
    padding: 3px 15px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px dashed #a2b1c6;
    span.pvtAttr {
      display: none;
    }
  }
}
.pvtVals {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  padding-bottom: 12px;
}
.pvtRenderers {
  padding-left: 5px;
  user-select: none;
}
.pvtDropdown {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  margin: 3px;
}
.pvtDropdownIcon {
  float: right;
  color: #a2b1c6;
}
.pvtDropdownCurrent {
  text-align: left;
  border: 1px solid #a2b1c6;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  width: 210px;
  box-sizing: border-box;
  background: white;
}
.pvtDropdownCurrentOpen {
  border-radius: 4px 4px 0 0;
}
.pvtDropdownMenu {
  background: white;
  position: absolute;
  width: 100%;
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #a2b1c6;
  border-top: 1px solid #dfe8f3;
  box-sizing: border-box;
}
.pvtDropdownValue {
  padding: 2px 5px;
  font-size: 12px;
  text-align: left;
}
.pvtDropdownActiveValue {
  background: #ebf0f8;
}
.pvtRows {
  height: 35px;
}
.pvtTriangle {
  cursor: pointer;
  color: #506784;
}
.pvtHorizList {
  li {
    display: inline-block;
  }
}
.pvtVertList {
  vertical-align: top;
}
.pvtFilteredAttribute {
  font-style: italic;
}
.sortable-chosen {
  .pvtFilterBox {
    display: none !important;
  }
}
.pvtCloseX {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
}
.pvtDragHandle {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 18px;
  cursor: move;
  color: #a2b1c6;
}
.pvtButton {
  color: #506784;
  border-radius: 5px;
  padding: 3px 6px;
  background: #f2f5fa;
  border: 1px solid;
  border-color: #c8d4e3;
  font-size: 14px;
  margin: 3px;
  transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none !important;
  &:hover {
    background: #e2e8f0;
    border-color: #a2b1c6;
  }
  &:active {
    background: #d1dae6;
  }
}
.pvtFilterBox {
  input {
    border: 1px solid #c8d4e3;
    border-radius: 5px;
    color: #506784;
    padding: 0 3px;
    font-size: 14px;
    &:focus {
      border-color: #119dff;
      outline: none;
    }
  }
  z-index: 100;
  width: 300px;
  border: 1px solid #506784;
  background-color: #fff;
  position: absolute;
  text-align: center;
  user-select: none;
  min-height: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  h4 {
    margin: 15px;
  }
  p {
    margin: 10px auto;
  }
  button {
    color: #2a3f5f;
  }
  input[type='text'] {
    width: 230px;
    color: #2a3f5f;
    margin-bottom: 5px;
  }
}
.pvtCheckContainer {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow-y: scroll;
  width: 100%;
  max-height: 30vh;
  border-top: 1px solid #dfe8f3;
  p {
    margin: 0;
    margin-bottom: 1px;
    padding: 3px;
    cursor: default;
    &:hover {
      .pvtOnly {
        display: block;
      }
      .pvtOnlySpacer {
        display: none;
      }
    }
  }
  p.selected {
    background: #ebf0f8;
  }
}
.pvtOnly {
  display: none;
  width: 35px;
  float: left;
  font-size: 12px;
  padding-left: 5px;
  cursor: pointer;
}
.pvtOnlySpacer {
  display: block;
  width: 35px;
  float: left;
}
.pvtRendererArea {
  padding: 5px;
}
