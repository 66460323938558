.month_year li {
  float: none;
  margin-right: 20px;
  margin-bottom: 2px;
  text-align: left;
}
.steps-sect .step-section .btn.cen {
  margin: 0 auto;
  margin-top: 32px;
  display: inline-block;
}
.month_year.col li {
  float: none;
  margin-bottom: 11px;
}
