.nav-item {
  flex: 0 0 29%;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: none;
}

.step_bx .nav-link.active span {
  background: #5595ff;
  border-color: #5595ff;
  color: #fff;
}
.error-msg-csv {
  color: red;
}
