/* Step Profile Page */
.steps-sect {
  padding: 20px 0;
  .step-section {
    max-width: 860px;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
    }
    .panel {
      box-shadow: 0px 4px 16px #e8e8e8;
      background: #fff;
      display: block;
      padding: 60px 60px;
      margin-top: 20px;
    }
    .btn {
      margin: 0 auto;
      margin-top: 32px;
      display: flex;
    }
  }
  .sm {
    text-align: center;
    margin: 0 auto;
    .panel {
      margin-bottom: 20px;
    }
    a.link {
      color: #999;
      font-size: 20px;
    }
  }
}

/* Step top bar breadcrumb Page */
.steps-sect {
  .step-section {
    .step_bx {
      ul {
        position: relative;
        margin-top: 45px;
        list-style-type: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-bottom: 30px;
        li {
          flex: 0 0 25.333%;
          position: relative;
          text-align: center;
          &:after {
            content: '...';
            width: 100px;
            height: 2px;
            position: absolute;
            font-size: 73px;
            line-height: 0px;
            top: 0px;
            padding-left: 58px;
            padding-top: 6px;
            color: #c5c6cb;
          }
          &:last-child {
            &:after {
              opacity: 0;
            }
          }
          p {
            color: #c5c6cb;
            padding-top: 10px;
          }
        }
        li.active {
          p {
            color: #212529;
          }
        }
        li.complete {
          span {
            color: #2dcb73;
            &:after {
              color: #fff;
              text-align: center;
              content: '\f00c';
              font-family: 'FontAwesome';
              font-size: 27px;
              position: relative;
              top: -1px;
              padding-right: 12px;
            }
          }
        }
      }
      li {
        span {
          display: block;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          text-align: center;
          padding: 11px 3px;
          box-sizing: border-box;
          font-weight: bold;
          color: #c5c6cb;
          font-size: 24px;
          margin: 0 auto;
          border: 1px solid #c5c6cb;
        }
      }
      li.active {
        span {
          background: #5595ff;
          border-color: #5595ff;
          color: #fff;
        }
      }
      li.complete {
        span {
          background: #2dcb73;
          border-color: #2dcb73;
          color: #fff;
        }
      }
    }
  }
}

/* import csv */
.form-group.img_bx {
  img {
    width: 120px;
  }
  h3 {
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 600;
    padding-top: 15px;
  }
}

/* Step2 */
.month_year {
  display: inline-block;
  width: 100%;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 5px 0px;
  }
  li {
    float: left;
    margin-right: 20px;
  }
}

.option_bx {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    li {
      position: relative;
      width: 170px;
      background: #fff;
      box-shadow: 0px 0px 4px #ccc;
      margin: 5px 21px 5px 0;
      flex: 0 0 28%;
      text-align: center;
      label {
        padding: 22px 20px;
        height: 100%;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &:before {
          display: none;
        }
        .fa {
          display: block;
          font-size: 25px;
          padding: 2px 0px 2px 0px;
        }
        display: block;
      }
      &:first-child {
        label {
          display: flex;
        }
      }
      input[type='radio']:checked + label {
        border: 1px solid #5595ff;
      }
    }
  }
}

.cardnumber {
  position: relative;
  i.fa.fa-credit-card {
    bottom: 17px;
    color: #c5c6cb;
    font-size: 24px;
    position: absolute;
    right: 20px;
  }
}

.carddetail {
  list-style-type: none;
  padding: 0;
  display: flex;
  li {
    .form-control {
      width: 93%;
    }
  }
}

.disclaimer-text {
  padding-top: 10px;
  p {
    b {
      font-weight: 600;
      color: #212529;
      font-size: 22px;
    }
  }
}

/*  Step3 page */
.upload-btn-wrapper {
  button.btn.btn-primary {
    margin-top: 20px;
  }
}
ul.carddetail {
  li {
    &:first-child {
      input.form-control {
        margin-left: 0px;
      }
    }
    &:last-child {
      input.form-control {
        margin-right: 0px;
      }
    }
    input.form-control {
      margin: 0px auto;
    }
  }
}
.setting_info {
  // width: 85%;
  margin: 0px auto;
}
.steps-sect {
  .step-section {
    .panel {
      .sub_pack {
        div {
          box-shadow: 0px 0px 6px 0px #ddd;
          padding: 25px;
          margin-bottom: 30px;
          color: #747474;
        }
      }
    }
  }
}
.steps-sect {
  .step-section {
    .panel {
      .sub_pack {
        div {
          h3 {
            font-weight: normal;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
.steps-sect {
  .step-section {
    .panel {
      .sub_pack {
        div {
          ul {
            li {
              list-style-type: circle;
              list-style-position: inside;
              padding: 2px 0px;
            }
          }
        }
      }
    }
  }
}
/*-------------------------------------------------------------*/
@media (max-width: 1366px) {
  .disclaimer .col-lg-11 {
    padding-left: 50px;
  }
}
@media (max-width: 1024px) {
  .steps-sect .step-section {
    max-width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .disclaimer .col-lg-11 {
    padding-left: 15px;
  }
  .setting_info .panel.card {
    padding: 20px 20px;
  }
  .info .disclaimer {
    margin-top: 0px !important;
  }
}

@media (max-width: 770px) {
  .steps-sect .step-section .step_bx ul li {
    flex: 0 0 32.333%;
  }
}

@media (max-width: 640px) {
  .steps-sect .step-section h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .steps-sect .step-section {
    max-width: 96%;
    margin: 0 auto;
  }
  .steps-sect .step-section .panel {
    padding: 30px 30px;
    margin-top: 20px;
  }
  .steps-sect .step-section .step_bx ul li:after {
    content: '...';
    width: 62px;
    height: 2px;
    position: absolute;
    font-size: 45px;
    line-height: 0px;
    top: 0px;
    padding-left: 37%;
    padding-top: 11%;
    color: #c5c6cb;
  }
  .setting_info .panel.cardinfo {
    padding: 25px;
  }
}

@media (max-width: 480px) {
  .steps-sect .step-section .panel {
    padding: 25px 20px;
  }
  .steps-sect .step-section h2 {
    font-size: 28px;
    font-weight: 600;
  }
  .step_bx li {
    display: inline-block;
    margin: 15px 20px;
  }
  .steps-sect .step-section {
    max-width: 100%;
    margin: 0 auto;
    margin: 0 0px;
  }
  .steps-sect .step-section .step_bx li span {
    width: 40px;
    height: 40px;
    padding: 6px 3px;
    font-size: 16px;
    margin: 0 auto;
  }
  .steps-sect .step-section .step_bx ul li {
    flex: 0 0 21.333%;
    padding: 0;
  }
  .steps-sect .step-section .step_bx ul li:after {
    width: 38px;
    height: 2px;
    font-size: 37px;
    line-height: 0px;
    top: 0px;
    padding-left: 0;
    padding-top: 11%;
    right: -49%;
  }
  .steps-sect .step-section .step_bx ul li.complete span:after {
    color: #fff;
    text-align: center;
    content: '\f00c';
    font-family: 'FontAwesome';
    font-size: 20px;
    position: relative;
    top: -2px;
    right: 3px;
  }
  .option_bx ul li {
    margin: 5px 10px 5px 0;
    flex: 0 0 32%;
    text-align: center;
  }
  .option_bx ul li label {
    padding: 15px 5px;
  }
  .steps-sect .sm a.link {
    color: #999;
    font-size: 16px;
  }
}
