.table-committees {
  tr {
    th {
      font-size: 14px;
      color: #1c2d40;
    }
    td {
      padding: 32px 19px;
      font-size: 22px;
      color: #1c2d40;
    }
  }
}

.list-search > div > ul > li {
  border: 1px solid #c5c6cb;
  height: 60px;
  font-size: 20px;
  padding: 13px 22px;
  border-radius: 5px;
  span {
    font-size: 20px;
    color: #ccc;
  }
  svg {
    display: none;
  }
}

.list-search .iRISHI {
  padding: 14px 20px;
  span {
    font-size: 18px;
  }
  .cMdXnC {
    border: 1px solid #c5c6cb;
    border-radius: 5px;
  }
}

.added {
  ul {
    padding: 0;
    li {
      border-bottom: 1px solid #e5e6ea;
      list-style-type: none;
      padding: 15px 20px;
      font-size: 20px;
      .close {
        background: #1c2d40;
        color: #fff;
        opacity: 1;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 4px 3px;
        font-weight: normal;
        font-size: 14px;
        font-weight: 300 !important;
      }
    }
  }
  h3 {
    background: #f9fbfc;
    padding: 20px 20px;
  }
}
form .panel.center {
  .form-group.added {
    ul {
      li {
        .close {
          float: right;
          padding-top: 2px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .App-header img.logoinner {
    width: 190px !important;
  }
}
@media (max-width: 767px) {
  .scrolling {
    overflow-x: auto;
  }
  .scrolling table {
    table-layout: auto;
  }
  .csv_row.heading-membership h2 {
    font-size: 20px;
    padding-top: 5px;
  }
  .csv_row.heading-membership .btn_col.last a.btn.btn-primary {
    font-size: 14px;
    padding: 10px 15px;
  }
}
@media (max-width: 480px) {
  .csv_row.heading-membership {
    display: block;
  }
  .csv_row.heading-membership .btn_col {
    float: left;
    margin-right: 10px;
  }
  .csv_row.heading-membership .btn_col.last {
    position: relative;
    float: none;
    text-align: right;
  }
  .csv_row.heading-membership .btn_col {
    float: left;
    margin-right: 0px;
    position: absolute;
  }
  .csv_row.heading-membership h2 {
    text-align: center !important;
    padding: 0px !important;
  }
  .csv_row.heading-membership .btn_col.last {
    position: relative;
    float: none;
    text-align: center;
    margin: 0px !important;
  }
}
