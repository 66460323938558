.tags {
  margin-bottom: 20px;
  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-right: 10px;
      display: inline-block;
      a {
        border: 1px solid #c5c6cb;
        border-radius: 74px;
        text-align: center;
        margin-bottom: 8px;
        padding: 10px 20px;
        color: #c5c6cb;
        display: block;
        font-size: 18px;
        text-decoration: none;
        font-weight: 600;
        &:hover {
          background: #c5c6cb;
          color: #fff;
        }
      }
      a.active {
        background: #5595ff;
        border-color: #5595ff;
        color: #fff;
        padding: 10px 16px;
      }
    }
  }
}

.error-msg {
  color: red;
}

.profile_bx {
  display: flex;
  .profile_left {
    flex: 0 0 35%;
    margin-right: 40px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 32px;
    }
  }
  .profile_right {
    flex: 0 0 62%;
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}

.profilepic {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

.map {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 280px;
  img {
    width: 100%;
  }
}

.tab_bar {
  padding: 50px 0;
  .react-tabs__tab-list {
    border-bottom: 1px solid #c5c6cb;
    margin-bottom: 60px;
  }
  .react-tabs__tab {
    font-size: 22px;
    padding: 19px 15px 19px 15px;
    color: #c5c6cb;
    font-weight: 600;
    margin-right: 10px;
  }
  .react-tabs__tab--selected {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px solid #5595ff;
    color: #333;
  }
}

.panel.info {
  padding: 0;
  margin-top: 30px;
}

.panel-header {
  background: #f9fbfc;
  padding: 30px;
}

.panel-header h2 {
  font-size: 24px;
  display: inline-block;
  margin-top: 0;
}

.panelbody {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}

.panelbody .col1 {
  flex: 0 0 29%;
}

.panelbody .col2 {
  flex: 0 0 33%;
  padding: 0 15px;
}
.panelbody .col1 ul {
  padding: 0;
  list-style-type: none;
}

.panelbody .col1 li {
  font-size: 20px;
  display: flex;
  margin-bottom: 17px;
  word-break: break-word;
}

.panelbody .col1 li span {
  display: inline-block;
  text-align: right;
  color: #969696;
  margin-right: 12px;
  flex: 0 0 50%;
}
.panelbody textarea.form-control {
  min-height: 109px;
}
.panelbody a.btn {
  text-decoration: none;
  box-shadow: none;
}
.panel-header .pull-right {
  margin-top: -8px;
}

.panel-header .pull-right a.btn {
  box-shadow: none;
  border-radius: 57px;
  padding: 8px 20px;
  font-size: 18px;
}
.full_width {
  margin-top: 30px;
  width: 100%;
}

.table-information tr th {
  padding: 5px;
  background: none;
  border: none;
}

.table-information tr td {
  border: none;
  padding: 7px;
}

.table-information tr th {
  padding: 5px 30px;
  background: none;
  border: none;
  font-size: 20px;
}

.table-information tr td {
  border: none;
  padding: 5px 30px;
  font-size: 20px;
}
.table-information tr td:nth-child(2),
.table-information tr th:nth-child(2) {
  width: 60%;
}
.table-information tr td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.table-information {
  width: 80%;
}
.full_width h3 {
  font-size: 26px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 25px;
  font-weight: 600;
}
.pull-right span {
  padding: 18px;
  cursor: pointer;
}
a.btn.link {
  color: #5595ff;
  font-size: 20px;
}

.panelbody .col1.spc {
  flex: 0 0 21%;
}
.panelbody .col1.spc li span {
  flex: 0 0 50%;
  margin-right: 20px;
}

.panelbody .col1.spc2 {
  flex: 0 0 39%;
  padding: 0 90px 0 15px;
}
.panelbody .col1.spc2 li span {
  flex: 0 0 42%;
}
.panelbody .col2.spc3 {
  flex: 0 0 33%;
}

.profile-page{
  .csv_row.heading-membership{
    .btn_col.last{
      position: relative;
      right: 0;
    }
  }
}

@media (max-width: 1680px) {
  .panelbody .col1.spc {
    flex: 0 0 24%;
  }
  .panelbody .col1.spc {
    flex: 0 0 24%;
  }
  .panelbody .col2.spc3 {
    flex: 0 0 37%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .full_width h3 {
    font-size: 20px;
  }
  .panelbody .col1 li {
    font-size: 16px;
  }
  .panel-header {
    padding: 20px;
  }
  .panel-header h2 {
    font-size: 20px;
    display: inline-block;
  }
  .panelbody .col1 {
    flex: 0 0 100%;
  }
  .panelbody .col1 li span {
    text-align: left;
  }
  .panelbody .col2 {
    flex: 0 0 100%;
  }
  .panelbody {
    padding: 20px 20px;
  }
  .panel-header .pull-right a.btn {
    font-size: 16px;
  }
  .table-information {
    width: 100%;
  }
  .table-information tr td {
    border: none;
    padding: 5px 16px;
    font-size: 16px;
  }
  .table-information tr th {
    padding: 5px 16px;
    background: none;
    border: none;
    font-size: 16px;
  }
  .table-information tr td:nth-child(2),
  .table-information tr th:nth-child(2) {
    width: 46%;
  }
  .icons_list a {
    font-size: 20px;
  }
}
@media (max-width: 770px) {
  .profile_bx .profile_left {
    flex: 0 0 100%;
    margin-right: 0;
  }
  .profile_bx .profile_right {
    flex: 0 0 100%;
    padding: 25px 20px;
  }
  .profile_bx {
    display: flex;
    flex-direction: column;
  }
  .tags ul li a {
    font-size: 16px;
  }
  .tab_bar .react-tabs__tab {
    font-size: 18px;
    padding: 19px 14px 19px 14px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .panelbody {
    padding: 20px 20px;
    width: 100%;
  }
}
.mark-div {
  // width: 50px;
  // background: red;
  // text-align: center;
  // width: 18px;
  // padding: 3px;
  // height: 18px;
  // margin: auto;
  // color:#fff;
  // border-radius: 50%;
  // width: 50px;
  // background: red;
  // text-align: center;
  // width: 25px;
  // padding: 6px;
  // height: 24px;
  // margin: auto;
  // color: #fff;
  img {
    width: 40px !important;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.bs-btn-area {
  .btn {
    padding: 0.375rem 0.75rem;
    margin: 5px 5px 10px;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 0;
  }
}

.data-verification-popup{
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;

    &.loading {
      padding: 4rem;
    }
  }
  .table {
    tr{
      th{
        padding: 0.5rem 0.5rem;
        border-bottom: 1px solid;
        border-color: inherit;
        font-size: var(--bs-body-font-size);
      }

      td {
        padding: 0.5rem 0.5rem;
        text-align: left;
        border-bottom: 1px solid ;
        font-size: 22px;
        border-color: inherit;
        font-size: var(--bs-body-font-size);

        &.empty-cell {
          background: #f79696;
          color: #fff;
        }
      }
    }
  }
}
