.panel.center.event {
  max-width: 964px;
}

.form-control-main {
  border: 1px solid #c5c6cb;
  border-radius: 5px;
  width: 110% !important;
  height: 60px;
  padding: 1px 5px !important;
  // font-size: 16px !important;
  box-sizing: border-box;
  margin-bottom: 6px;
  font-size: 11px !important;
}
.selecter-span {
  text-align: center;
  padding: 14px !important;
}
.btn_col.last a {
  font-size: 20px;
  text-decoration: underline;
}
.zone-selecter input {
  padding: 0px !important;
  font-size: 14px !important;
  height: 100%;
  border: 0px !important;
}
.zone-selecter ul li {
  font-size: 14px;
  padding: 4px 0px;
  width: 100% !important;
}
.form-group.cardnumber .react-datepicker {
  top: 0 !important;
}
ul.selected_list.checklist.copy-listul li {
  .addbtn-new {
    background: #ffcb3a;
    border: 1px solid #ffcb3a;
    text-transform: capitalize;
    padding: 3px 10px;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    float: right;
  }
  .added-btn {
    // cursor: crosshair;
    cursor: default;
  }
}
ul.selected_list.checklist.copy-listul li .checkbox-class {
  float: right;
}

.zone-selecter ul {
  background: #fff;
  z-index: 99999999;
}
ul.selected_list.checklist.copy-listul {
  padding: 0px 15px;
  box-shadow: 0px 0px 6px 2px #ddd;
}
ul.selected_list.checklist.copy-listul li {
  width: 100%;
  border-bottom: 1px solid hsla(0, 4%, 82%, 0.53);
  margin: 0px 0px;
  padding: 8px 0px;
}
.time li {
  flex: 0 0 25%;
}

.loc li {
  flex: 0 0 50%;
}
.loc li:last-child input.form-control {
  margin-right: 0px;
  margin-left: 0;
}

.checklist {
  list-style-type: none;
  padding: 0;
  li {
    margin-bottom: 10px;
  }
}

.cent {
  text-align: center;
  margin-top: 45px;
}
ul.carddetail.time.row {
  li.col-sm-4 {
    flex: 0 0 33.3%;
  }
}
ul.carddetail.time.row {
  li.col-sm-4 {
    select.form-control {
      width: 100%;
    }
  }
}
.form-group.cardnumber {
  span.calendricon {
    position: absolute;
    right: 16px;
    top: 54px;
    color: #777;
  }
}
.margin-bottomnew {
  margin-bottom: 20px;
}
.form-group.dropnew-alignment {
  ul.list-dropdown-new {
    padding: 0px 0px;
    box-shadow: 0px 0px 4px 0px #ddd;
  }
}
.form-group.dropnew-alignment {
  ul.list-dropdown-new li {
    list-style: none;
    padding: 10px 15px;
    position: relative;
  }
}
.form-group.dropnew-alignment {
  ul.list-dropdown-new {
    li {
      span {
        position: absolute;
        right: 15px;
        top: 7px;
      }
    }
  }
}

input[type='checkbox']:checked + label::before {
  font-size: 14px !important;
}

.form-group.dropnew-alignment {
  ul.list-dropdown-new {
    li {
      .addbtn-new {
        background: #ffcb3a;
        border: 1px solid #ffcb3a;
        text-transform: capitalize;
        padding: 3px 10px;
        border-radius: 50px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
.form-group.dropnew-alignment {
  ul.list-dropdown-new {
    li.selected {
      background: #f9fbfc;
    }
  }
}
table.table.table_unfix.custome-append-new {
  tbody {
    a.status {
      color: #555;
      border: 1px solid #555;
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 50px;
      /* width: auto; */
      /* word-break: keep-all; */
      width: 100%;
    }
  }
}
.form-control.selecter-span {
  width: 100%;
  display: inline-block;
}
table.table.table_unfix.custome-append-new {
  tbody {
    a.status.active {
      color: #fff;
      border: 1px solid #1e74e3;
    }
  }
}
table.table.table_unfix.custome-append-new {
  tr {
    th:nth-child(3) {
      width: 330px;
    }
  }
}
table.table.table_unfix.custome-append-new {
  tr {
    td {
      font-size: 16px;
      padding: 13px 10px;
    }
  }
}
.checkbox-class {
  display: block !important;
}

table.table.table_unfix.custome-append-new {
  tr {
    td:last-child {
      a.close {
        color: #fff;
        background: #333;
        opacity: 1;
        font-weight: normal;
        /* padding: 3px 0px; */
        width: 20px;
        height: 20px;
        text-align: center;
        border: 5;
        border-radius: 50%;
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }
}

@media (max-width: 767px) {
  table.table.table_unfix.custome-append-new tr th:nth-child(3) {
    width: auto;
  }
}
@media (max-width: 600px) {
  ul.carddetail.time.row {
    display: block;
  }
  ul.carddetail.time.row li.col-sm-3 {
    width: 100%;
    display: block;
    /* flex: 100% !important; */
  }
  .carddetail li .form-control {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  ul.carddetail.loc {
    display: block;
  }
  ul.carddetail.loc li {
    width: 100%;
    margin-bottom: 20px;
  }
  ul.carddetail.loc li:last-child {
    margin-bottom: 0px;
  }
}
