.form-check{
    input[type=checkbox]{
        display: inline-block;
        cursor: pointer;
        & + label {
            &:before {
                content: none;
            }
        }
    }
}


.btn{
    &.btn-outline-primary{
        &.active {
            background: #fff;
            border: 0;
            border-bottom: 2px solid #0d6efd;
            &:hover{
                color: #000;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
}   