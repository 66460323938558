.col12.col_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  .form-group {
    flex: 0 0 47%;
    margin: 10px 15px;
  }
}
.display-none-btn {
  display: none;
}
.margin-btn {
  margin: 20px;
}
.center-btn {
  text-align: center;
}
.capitalize-text {
  text-transform: capitalize;
}

.custom-field-table {
  span {
    cursor: pointer;
    background: #dcdcdc;
    float: right;
    margin: 4px;
    padding: 4px 10px;
    border-radius: 15px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}
.form-group.db {
  position: inherit;
}
.margin-link {
  margin-top: 20px !important;
}
.save-continue-btn {
  margin: 18px;
}
// .react-tabs .form-control {
//     text-transform: capitalize!important;
// }

.panelbody {
  .form_opt {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .col6 {
      flex: 0 0 50%;
      padding: 0 15px;
    }
  }
  .form_opt.cent {
    .btn {
      display: block;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  .form_opt.two_col {
    .form-group {
      flex: 0 0 47%;
      margin: 10px 15px;
    }
    .full_head {
      flex: 0 0 100%;
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: normal;
    }
    .form_btn_rw {
      flex: 0 0 100%;
      text-align: center;
    }
    .form-group.last {
      flex: 0 0 98.5%;
    }
  }
}
.react-tabs {
  .panelbody {
    .btn.btn-primary {
      margin-left: 15px;
    }
  }
}
.form_opt.two_col {
  .full_head {
    padding-left: 15px;
  }
}
// .react-tabs{
//     .react-datepicker-popper {
//         position: relative !important;
//         top: 63px !important;
//       }
// }
.cardnumber i.fa.fa-credit-card {
  top: 49px;
}
/*-------------------------------------------------------------------------------------*/
@media (max-width: 1366px) {
  .panelbody .col1.spc {
    flex: 0 0 25%;
  }
  .panelbody .col1.spc2 {
    flex: 0 0 38%;
    padding: 0 20px 0 15px;
  }
  .panelbody .form_opt.two_col .form-group,
  .col12.col_wrap .form-group {
    flex: auto;
    width: 46%;
    display: inline-block;
  }
  .panelbody .form_opt {
    width: 100%;
    flex-wrap: unset;
    display: block;
    text-align: center;
  }
  .panelbody .form_opt .form-group label {
    text-align: left;
  }
  .panelbody .form_opt.two_col .form-group.last {
    width: 95%;
    margin: 0px;
  }
  .panelbody .form_opt.two_col .full_head {
    text-align: left;
    padding-left: 21px;
  }
}
@media (max-width: 1200px) {
  .react-tabs__tab-panel {
    .col-md-5.col-xs-12 {
      width: 100%;
    }
  }
  .react-tabs__tab-panel {
    .col-md-6.col-xs-12 {
      width: 100%;
    }
  }
}
@media (max-width: 1024px) {
  .panelbody .col1.spc {
    flex: 0 0 45%;
  }
  .panelbody .col1.spc2 {
    flex: 0 0 50%;
  }
  .panelbody .col2.spc3 {
    flex: 0 0 100%;
  }
  .panelbody .col1 {
    flex: 0 0 50% !important;
  }
}
@media (max-width: 992px) {
  .panelbody .form_opt.two_col .form-group,
  .col12.col_wrap .form-group {
    width: 44%;
  }
  .panelbody .form_opt.two_col .form-group.last {
    width: 92%;
    margin: 0px;
  }
}
@media (max-width: 768px) {
  .panelbody .form_opt.two_col .form-group,
  .col12.col_wrap .form-group {
    width: 100%;
    margin: 10px 0px;
  }
  .panelbody .form_opt.two_col .form-group.last {
    width: 100%;
  }
}

/** profile main css */
.tags {
  margin-bottom: 20px;
  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-right: 10px;
      display: inline-block;
      a {
        border: 1px solid #c5c6cb;
        border-radius: 74px;
        text-align: center;
        margin-bottom: 8px;
        padding: 10px 20px;
        color: #c5c6cb;
        display: block;
        font-size: 18px;
        text-decoration: none;
        font-weight: 600;
        &:hover {
          background: #c5c6cb;
          color: #fff;
        }
      }
      a.active {
        background: #5595ff;
        border-color: #5595ff;
        color: #fff;
        padding: 10px 16px;
      }
    }
  }
}

.error-msg {
  color: red;
}

.profile_bx {
  display: flex;
  .profile_left {
    flex: 0 0 35%;
    margin-right: 40px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 32px;
    }
  }
  .profile_right {
    flex: 0 0 62%;
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}

.profilepic {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

.map {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 280px;
  img {
    width: 100%;
  }
}

.tab_bar {
  padding: 50px 0;
  .react-tabs__tab-list {
    border-bottom: 1px solid #c5c6cb;
    margin-bottom: 60px;
  }
  .react-tabs__tab {
    font-size: 22px;
    padding: 19px 15px 19px 15px;
    color: #c5c6cb;
    font-weight: 600;
    margin-right: 10px;
  }
  .react-tabs__tab--selected {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px solid #5595ff;
    color: #333;
  }
}

.panel.info {
  padding: 0;
  margin-top: 30px;
}

.panel-header {
  background: #f9fbfc;
  padding: 30px;
}

.panel-header h2 {
  font-size: 24px;
  display: inline-block;
  margin-top: 0;
}

.panelbody {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}

.panelbody .col1 {
  flex: 0 0 29%;
}

.panelbody .col2 {
  flex: 0 0 33%;
  padding: 0 15px;
}
.panelbody .col1 ul {
  padding: 0;
  list-style-type: none;
}

.panelbody .col1 li {
  font-size: 20px;
  display: flex;
  margin-bottom: 17px;
  word-break: break-word;
}

.panelbody .col1 li span {
  display: inline-block;
  text-align: right;
  color: #969696;
  margin-right: 12px;
  flex: 0 0 50%;
}
.panelbody textarea.form-control {
  min-height: 109px;
}
.panelbody a.btn {
  text-decoration: none;
  box-shadow: none;
}
.panel-header .pull-right {
  margin-top: -8px;
}

.panel-header .pull-right a.btn {
  box-shadow: none;
  border-radius: 57px;
  padding: 8px 20px;
  font-size: 18px;
}
.full_width {
  margin-top: 30px;
  width: 100%;
}

.table-information tr th {
  padding: 5px;
  background: none;
  border: none;
}

.table-information tr td {
  border: none;
  padding: 7px;
}

.table-information tr th {
  padding: 5px 30px;
  background: none;
  border: none;
  font-size: 20px;
}

.table-information tr td {
  border: none;
  padding: 5px 30px;
  font-size: 20px;
}
.table-information tr td:nth-child(2),
.table-information tr th:nth-child(2) {
  width: 60%;
}
.table-information tr td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.table-information {
  width: 80%;
}
.full_width h3 {
  font-size: 26px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 25px;
  font-weight: 600;
}
.pull-right span {
  padding: 18px;
  cursor: pointer;
}
a.btn.link {
  color: #5595ff;
  font-size: 20px;
}

.panelbody .col1.spc {
  flex: 0 0 21%;
}
.panelbody .col1.spc li span {
  flex: 0 0 50%;
  margin-right: 20px;
}

.panelbody .col1.spc2 {
  flex: 0 0 39%;
  padding: 0 90px 0 15px;
}
.panelbody .col1.spc2 li span {
  flex: 0 0 42%;
}
.panelbody .col2.spc3 {
  flex: 0 0 33%;
}

@media (max-width: 1680px) {
  .panelbody .col1.spc {
    flex: 0 0 24%;
  }
  .panelbody .col1.spc {
    flex: 0 0 24%;
  }
  .panelbody .col2.spc3 {
    flex: 0 0 37%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .full_width h3 {
    font-size: 20px;
  }
  .panelbody .col1 li {
    font-size: 16px;
  }
  .panel-header {
    padding: 20px;
  }
  .panel-header h2 {
    font-size: 20px;
    display: inline-block;
  }
  .panelbody .col1 {
    flex: 0 0 100%;
  }
  .panelbody .col1 li span {
    text-align: left;
  }
  .panelbody .col2 {
    flex: 0 0 100%;
  }
  .panelbody {
    padding: 20px 20px;
  }
  .panel-header .pull-right a.btn {
    font-size: 16px;
  }
  .table-information {
    width: 100%;
  }
  .table-information tr td {
    border: none;
    padding: 5px 16px;
    font-size: 16px;
  }
  .table-information tr th {
    padding: 5px 16px;
    background: none;
    border: none;
    font-size: 16px;
  }
  .table-information tr td:nth-child(2),
  .table-information tr th:nth-child(2) {
    width: 46%;
  }
  .icons_list a {
    font-size: 20px;
  }
}
@media (max-width: 770px) {
  .profile_bx .profile_left {
    flex: 0 0 100%;
    margin-right: 0;
  }
  .profile_bx .profile_right {
    flex: 0 0 100%;
    padding: 25px 20px;
  }
  .profile_bx {
    display: flex;
    flex-direction: column;
  }
  .tags ul li a {
    font-size: 16px;
  }
  .tab_bar .react-tabs__tab {
    font-size: 18px;
    padding: 19px 14px 19px 14px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .panelbody {
    padding: 20px 20px;
    width: 100%;
  }
}
.ddddd {
  width: 50px;
  background: red;
  text-align: center;
  width: 18px;
  padding: 3px;
  height: 18px;
  margin: auto;
  color: #fff;
}
