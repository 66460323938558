.admin_bx {
  display: flex;
  flex-wrap: wrap;
}
.logout-btn {
  width: auto;
  height: 55px;
  margin: 10px;
}

.admin_bx .admin_bx_col {
  flex: 0 0 31.2%;
  background: #fff;
  box-shadow: 0px 4px 16px #e8e8e8;
  text-align: center;
  padding: 70px 20px;
  margin: 15px 35px 15px 0;
}

.admin_bx .admin_bx_col img {
  width: 95px;
  margin-bottom: 20px;
}

.admin_bx .admin_bx_col h4 {
  color: #333;
  font-weight: 600;
  font-size: 26px;
  text-decoration: none;
}
.csv_row.txtleft h2 {
  text-align: left;
  margin-top: 30px;
}
.admin_bx .admin_bx_col:nth-child(3n) {
  margin-right: 0;
}
.model-content {
  padding: 30px 40px;
}

.eng ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.eng ul li {
  margin-bottom: 15px;
  font-size: 20px;
}

.border-top {
  padding: 21px 0 5px;
  font-size: 20px;
}
.border-top a {
  outline: none;
  font-weight: 600;
}

@media (max-width: 1366px) {
  .admin_bx .admin_bx_col {
    flex: 0 0 31%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .admin_bx .admin_bx_col {
    flex: 0 0 31%;
    margin-right: 28px;
  }
  .admin_bx .admin_bx_col h4 a {
    font-size: 20px;
    text-decoration: none;
  }
  .admin_bx .admin_bx_col {
    padding: 35px 20px;
  }
}

@media (min-width: 771px) and (max-width: 991px) {
  .admin_bx .admin_bx_col h4 a {
    font-size: 16px;
    text-decoration: none;
  }
  .admin_bx .admin_bx_col img {
    width: 65px;
    margin-bottom: 20px;
  }
  .admin_bx .admin_bx_col {
    padding: 40px 20px;
    margin: 15px 20px 15px 0;
  }
}

@media (max-width: 770px) {
  .admin_bx .admin_bx_col {
    flex: 0 0 31%;
    padding: 40px 20px;
    margin: 15px 20px 10px 0;
  }
  .admin_bx .admin_bx_col img {
    width: 79px;
    margin-bottom: 20px;
  }
  .admin_bx .admin_bx_col h4 a {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }
  .admin_bx .admin_bx_col:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .admin_bx .admin_bx_col {
    flex: 0 0 30.5%;
    padding: 25px 9px;
    margin: 15px 18px 10px 0;
  }
}

@media (max-width: 480px) {
  .admin_bx .admin_bx_col img {
    width: 58px;
    margin-bottom: 15px;
  }
  .admin_bx .admin_bx_col {
    flex: 0 0 47%;
    padding: 25px 15px;
    margin: 10px 15px 10px 0;
  }
  .admin_bx .admin_bx_col:nth-child(2n) {
    margin-right: 0;
  }
  .admin_bx .admin_bx_col:nth-child(3n) {
    margin-right: 15px;
  }
  .admin_bx .admin_bx_col:nth-child(6) {
    margin-right: 0px;
  }
  .admin_bx .admin_bx_col h4 a {
    font-size: 16px;
    text-decoration: none;
  }
}
