.panel.summerbash-pannel {
  background: #f1f1f1;
  box-shadow: none;
  text-align: left;
}
.panel.summerbash-pannel {
  .summerbash-new {
    .col-sm-12 {
      padding: 0px;
    }
  }
}
.summerbash-new {
  h4 {
    font-weight: 600;
    text-transform: capitalize;
  }
}
.summerbash-new {
  h5 {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
}
.summerbash-new {
  p {
    font-size: 18px;
  }
}
.address-descrip-new {
  h3 {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
  }
}
.description-new {
  padding-right: 30px;
}

.description-p {
  text-align: justify;
}
