.csv_row {
  display: flex;
  margin-bottom: 25px;
  h2 {
    flex: 1;
    text-align: center;
    margin-top: 0;
    font-size: 30px;
  }
  .btn_col.last {
    text-align: right;
  }
  .btn_col {
    a.backarrow {
      color: #999;
      font-size: 23px;
    }
  }
}

.table {
  tr {
    th {
      padding: 25px 20px;
      border: none !important;
    }
    td {
      padding: 15px 20px;
    }
  }
}

.table-member {
  tr {
    td {
      vertical-align: middle;
      font-size: 20px;
      border-top: none;
    }
  }
}

.csv_row.heading-membership {
  position: relative;
  margin-bottom: 50px;
  padding-top: 20px;
  .btn_col.last {
    position: absolute;
    right: 0;
    margin-top: -8px;
    p {
      margin-bottom: 0;
      margin-top: 9px;
    }
    .btn {
      box-shadow: none;
      text-decoration: none;
      img {
        width: 13px;
        vertical-align: baseline;
      }
    }
    .btn.click_imgbtn {
      border: 1px solid #ccc;
      padding: 15px 19px;
      vertical-align: middle;
      img {
        width: 31px;
        vertical-align: middle;
      }
    }
  }
  .backarrow {
    img {
      width: 25px;
      position: relative;
      top: -3px;
    }
  }
}

.dd-wrapper {
  .dd-header-title {
    color: #c5c6cb;
  }
  .dd-list {
    .dd-list-item {
      font-size: 20px;
      font-weight: normal;
      padding: 20px 20px;
    }
    .dd-list-item:nth-child(2n + 1) {
      background: #f9fbfc;
    }
    .dd-list-item:hover {
      background-color: #5595ff !important;
    }
  }
}

/*=======popup===========*/

.styles_modal__gNwvD {
  width: 800px;
  padding: 0px;
  .styles_closeButton__20ID4 {
    top: 25px;
  }
}

.modal-header {
  text-align: center;
  background: #f5f5f5;
  border: none;
  padding: 26px 0px;
  h2 {
    font-size: 26px;
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
  .backarrow {
    img {
      font-size: 22px;
      width: 84px;
      padding-left: 60px;
      position: relative;
      top: -2px;
    }
  }
}

.model-content-edit {
  padding: 40px 60px;
  text-align: center;
  ul {
    list-style: none;
    display: inline-block;
    padding: 0px;
    li {
      display: inline-block;
      padding-right: 35px;
      label::before {
        border-radius: 0px;
      }
    }
  }
  input[type='checkbox']:checked + label::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    /* background: #5595ff; */
    left: 6px;
    top: 9px;
  }
  input[type='checkbox'] + label {
    position: relative;
  }
  input[type='checkbox']:checked + label::before {
    background-color: #fff;
    border-color: #5595ff;
  }
  .btn.btn-primary {
    padding: 15px 35px;
    border-radius: 3px;
  }
  .form-group {
    text-align: left;
  }
}

.mapping-fields__input-container {
  max-width: 100%;
  overflow: hidden;
}

textarea.form-control {
  min-height: 200px;
  padding: 15px 22px;
}
table.table.table-responsive.table-member.svc-import {
  th {
    width: 50%;
    padding: 20px 15px;
    border: none;
  }
}
table.table.table-responsive.table-member.svc-import {
  td {
    padding: 20px 15px;
    margin: 0px;
    vertical-align: middle;
  }
}
table.table.table-responsive.table-member.svc-import {
  .dd-header {
    height: 40px;
  }
}
table.table.table-responsive.table-member.svc-import {
  .dd-header {
    span.fa {
      font-size: 20px;
    }
  }
}
table.table.table-responsive.table-member.svc-import {
  .dd-header {
    .dd-header-title {
      text-transform: capitalize;
    }
  }
}
table.table.table-responsive.table-member.svc-import {
  .dd-wrapper {
    ul.dd-list {
      text-transform: capitalize;
    }
  }
}
.modal-header {
  a.backarrow {
    float: left;
    border: none;
    outline: none;
  }
}
@media (max-width: 1366px) {
  table.table.table-responsive.table-member.svc-import .dd-header .dd-header-title {
    text-transform: capitalize;
    font-size: 14px;
    margin: 0px;
    padding-left: 7px;
  }
  table.table.table-responsive.table-member.svc-import {
    table-layout: auto;
  }
}
@media (max-width: 767px) {
  .csv_row.heading-membership .btn_col.last a.btn {
    font-size: 14px;
    padding: 10px 15px;
  }
}
@media (max-width: 500px) {
  .table-responsive.table-member.svc-import .dd-header .dd-header-title {
    padding-left: 0px !important;
  }
  .dd-wrapper .dd-header span {
    margin-right: 4px !important;
  }
  .container {
    padding: 0px 10px !important;
  }
  table.table.table-responsive.table-member.svc-import td {
    padding: 20px 6px !important;
    margin: 0px;
    vertical-align: middle;
  }
}
