.btn-action{
  cursor: pointer;
}

.btn-nav {
  .btn {
    box-shadow: none;
    border: 1px solid #bdbdbd;
    color: #000;
    border-radius: 0;
    margin: 0 2px;

    &:hover{
      color: #fff;
    }
  }
}

.cardinfo {
  padding: 30px 30px;
  margin-bottom: 30px;
  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      font-size: 19px;
      span {
        font-weight: 600;
      }
    }
  }
  .panel_bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    margin: 0 -30px;
    padding: 17px 35px 0;
    font-size: 20px;
    span {
      float: right;
      .edit {
        margin-right: 15px;
      }
    }
  }
}

.payment_info {
  .react-tabs__tab {
    box-shadow: 0px 0px 4px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 185px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 20px;
    i {
      display: block;
      font-size: 28px;
      text-align: center;
    }
  }
  .react-tabs__tab--selected {
    border: 2px solid #5595ff;
  }
  .react-tabs__tab-list {
    border: none;
  }
}

.setting_info {
  .setting_topinfo {
    background: #f8faff;
    margin-bottom: 35px;
    padding: 36px 36px;
    display: inline-block;
    width: 100%;
    ul {
      padding: 0;
      list-style-type: none;
    }
    li {
      padding-top: 10px;
      float: left;
      margin-right: 5%;
      font-size: 20px;
      &:last-child {
        text-align: right;
        float: right;
        margin-right: 0;
        .link {
          margin-top: 31px;
          display: block;
          cursor: pointer;
        }
      }
    }
    h2 {
      color: #5595ff;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 0px;
      font-size: 30px;
    }
  }
}

.addLink {
  cursor: pointer;
  color: #5595ff;
}

.editor {
  margin: 18px 0 30px;
  border: 1px solid #c5c6cb;
  border-radius: 5px;
  height: 320px;
  padding: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.panel.card {
  box-shadow: none;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
  h1 {
    font-size: 54px;
    color: #5595ff;
  }
}

.addon_list {
  padding: 0;
  list-style-type: none;
  li a {
    color: #8b8b8b;
    text-decoration: underline;
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
  }
}

h2.custom,
.setting_info h2 {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 29px;
}
.App-setting {
  margin-bottom: 30px;
}

.disclaimer {
  margin-top: 30px;
  h2 {
    font-size: 22px;
  }
}
.showmore {
  text-align: center;
  padding: 25px 0;
  span {
    color: #333;
    font-size: 20px;
    font-weight: 600;
  }
}
.disclaimer.row {
  .col-lg-11 {
    p {
      padding-left: 20px;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.remove {
  color: red;
  outline: 0;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 1366px) {
  .panel.card {
    //padding: 65px 30px;
    padding: 0px 20px;
  }
}

@media (max-width: 1024px) {
  .cardinfo ul li {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .cardinfo .panel_bottom {
    font-size: 16px;
  }
  .addon_list li a {
    font-size: 16px;
  }
  .row.billling-invite .col-md-6.col-xs-12 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .setting_info .setting_topinfo h2 {
    margin-top: 8px;
    font-size: 28px;
  }
  .setting_info .setting_topinfo li {
    margin-right: 3%;
    font-size: 18px;
  }
  .panel.card {
    margin-top: 0;
    //padding: 65px 20px;
    padding: 0px 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .setting_info .setting_topinfo h2 {
    margin-top: 5px;
    font-size: 28px;
  }
  .setting_info .setting_topinfo li {
    float: left;
    margin-right: 4%;
    font-size: 16px;
  }
  .panel.card {
    margin-top: 0;
    // padding: 65px 20px;
    padding: 0px 10px;
  }
}

@media (max-width: 770px) {
  .setting_info .setting_topinfo li {
    margin-right: 5%;
    font-size: 16px;
  }
  .setting_info .setting_topinfo h2 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 24px;
  }
  .setting_info .setting_topinfo li {
    margin-right: 0;
    font-size: 16px;
    width: 50%;
    margin-bottom: 23px;
  }
  .setting_info .setting_topinfo li:last-child {
    width: 100%;
    margin-bottom: 10px;
  }
  .setting_info .setting_topinfo li:last-child .link {
    margin-top: 6px;
    display: block;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .setting_info .setting_topinfo ul li {
    width: 100%;
  }

  .setting_info .setting_topinfo {
    margin-bottom: 35px;
    padding: 20px 20px;
  }
  .setting_info .setting_topinfo h2 {
    font-size: 20px;
  }
  .setting_info .setting_topinfo li {
    font-size: 14px;
  }
  .setting_info .setting_topinfo li:last-child {
    width: 100%;
    margin-bottom: 10px;
  }
}
