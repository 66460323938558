.subscription-list.table.package-detail th{
    background: #2591af;
    color: #fff;
    font-weight: 400;
}

.subscription-list.table tr td,
.subscription-list.table tr th {
    padding: 0.5rem 0.5rem;
    font-size: initial;
}

.subscription-list.table thead th{
    border-bottom: 1px solid #000 !important;
    text-transform: initial;
}

.subscription-list.table tbody tr{
    border-bottom: 1px solid #e9d5d5 !important;
}

.subscription-list.table tbody th,
.subscription-list.table tbody td {
    text-transform: initial;
    border: none;
}

.subscription-list.table .subscription-name {
    display: flex;
    align-items: center;
}

.subscription-list.table .subscription-name .status {
    display: inline-block;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-left: 3px;
    border-radius: 50%;
}

.subscription-loader {
    position: absolute;
    background: rgb(253 253 253 / 62%);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscription-loader img {
    position: initial;
}