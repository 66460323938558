.btn-link {
    color: #fff;
    border-bottom: 1px dashed #fff;
    font-weight: bold;
}

.btn-link.btn-inverse {
    color: #000;
    border-bottom: 1px dashed #000;
    font-weight: bold;
    text-decoration: initial;
}

.btn-link.btn-inverse:hover {
    color: #8a6d3b;
    border-bottom: 1px dashed #8a6d3b;
    text-decoration: none;
}

.btn-link:hover {
    color: #e7dfdf;
    border-bottom: 1px dashed #e7dfdf;
    text-decoration: none;
}