.icons_list a {
  color: #c5c6cb;
  text-decoration: none;
  font-size: 24px;
  line-height: 20px;
  margin-right: 19px;
  &:hover {
    color: #222;
  }
}
.table-campaign {
  table-layout: inherit;
  tr {
    th {
      font-size: 20px;
      color: #1c2d40;
    }
    td {
      padding: 30px 19px;
      font-size: 20px;
      color: #1c2d40;
    }
  }
}
