.text-center.successfully-text {
  text-align: center;
  height: 100vh;
  display: table;
  width: 100%;
}
.text-center.successfully-text {
  .centered-contentnew {
    display: table-cell;
    vertical-align: middle;
  }
}
.text-center.successfully-text {
  .centered-contentnew {
    .check-new::before {
      content: '\f058';
      font-family: 'FontAwesome';
      color: #0bb50b;
      font-size: 140px;
    }
  }
}
