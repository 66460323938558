.sweet-loading {
  padding: 10px;
  right: 0px;
  position: absolute;
  top: 0px;
}
.form-group.drop-selector-new i.fa.fa-caret-down {
  position: absolute;
  right: 15px;
  top: 59px;
  font-size: 20px;
}
.form-group.drop-selector-new select {
  background-image: none;
}
