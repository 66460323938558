.Toastify__toast-container {
    width: auto;
    max-width: 450px;
}

.pricing-container {
    position: relative;
}

.pricing-container h1{
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.pricing-header {
    margin: 30px 0 60px;
}

.pricing-container h1 .pricing-period {
    font-size: 20px;
    font-weight: 400;
}
.pricing-container h4{
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
}

.panel.price-scheme {
    padding: 0;
}

.panel.price-scheme.featured-plan {
    box-shadow: 0px 14px 20px #837c7c;
}

.pricing-container .pricing-content {
    position: relative;
    min-height: 100px;
}
.price-scheme .panel-heading.price-scheme-head {
    border: 0;
    padding: 40px 45px 0;
}

.price-scheme.featured-plan .price-scheme-head {
    background: #748fef;
    color: #fff;
}

.price-scheme-head .price-scheme-amount {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
}

.price-scheme-head .price-scheme-amount,
.price-scheme-head .price-scheme-name,
.price-scheme-head .price-scheme-icon {
    text-align: center;
}

.pricing-header-period {
    text-align: center;
    margin: 20px 0 0;
}

.pricing-header-period a {
    color: #918a8a;
}

.pricing-header-period a.active {
    color: #000;
    font-weight: bold;
    background: transparent;
}

.price-scheme-body{
    font-size:12px;
    padding: 40px 45px 30px;
}

.price-scheme.featured-plan .price-scheme-body {
    color: #fff;
    background: #7c94eb;
}

.btn.btn-switch-plan {
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.btn.btn-switch-plan.btn-primary{
    color: #fff;
    background-color: #7c94eb;
    border-color: #7c94eb;
}

.btn.btn-current-plan {
    cursor: no-drop !important;
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    color: #000;
    background-color: #fff;
    border-color: #a59b9b;
}

.price-scheme.featured-plan .btn-switch-plan {
    background: #748eef;
    color: #fff;
    border: 1px solid #e0e2eb;
}

.btn.btn-current-plan:hover{
    color: #000;
    background-color: #fff;
    border-color: #a59b9b;
}

.package-footnote {
    text-align: center;
    margin: 10px 0;
    font-size: 15px;
    font-weight: bold;
    color: #766d6d;
}

.subscription-loader {
    position: absolute;
    background: rgb(253 253 253 / 62%);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscription-loader img {
    position: initial;
}