.eventlist {
  box-shadow: 0px 0px 6px #dedede;
  display: flex;
  margin-bottom: 30px;
}
h3.no-event {
  text-align: center;
  padding: 40px;
}
.event_cal {
  flex: 0 0 20%;
  background: #f9fbfc;
  padding: 40px 40px;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      font-size: 20px;
      color: #828282;
      i {
        margin-right: 14px;
        font-size: 24px;
        width: 22px;
      }
    }
  }
}

.event_summ {
  flex: 0 0 80%;
  .event_header {
    border-bottom: 1px solid #e1e2e7;
    padding: 20px 40px;
    h2 {
      font-weight: 400;
      font-size: 30px;
      margin-top: 17px;
    }
  }
  .event_desc {
    padding: 30px 40px;
  }
  .btn_rw {
    margin-top: 30px;
    a.btn {
      box-shadow: none;
      padding: 9px 35px;
    }
  }
}

.eventlist .event_cal {
  ul {
    li {
      padding-left: 30px;
      position: relative;
      font-size: 18px;
    }
  }
}
.eventlist {
  .event_cal {
    ul {
      li {
        .fa {
          position: absolute;
          left: 0;
          width: 18px;
          font-size: 20px;
          padding-top: 3px;
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .event_summ {
    flex: 0 0 75%;
  }
  .event_cal {
    flex: 0 0 25%;
  }
}

@media (max-width: 1680px) {
  .event_summ {
    flex: 0 0 70%;
  }
  .event_cal {
    flex: 0 0 30%;
  }
}

@media (max-width: 1024px) {
  .event_summ {
    flex: 0 0 60%;
  }
  .event_cal {
    flex: 0 0 40%;
  }
}

@media (max-width: 770px) {
  .event_cal {
    flex: 0 0 100%;
    padding: 20px 20px;
  }
  .event_summ {
    flex: 0 0 100%;
  }
  .eventlist {
    margin-bottom: 30px;
    flex-direction: column;
  }
  .event_summ .event_desc {
    padding: 20px 20px;
  }
  .event_summ .event_header {
    padding: 10px 20px;
  }
}

.eventlist {
  .event_cal {
    li {
      i.fa.fa-calendar-o {
        position: absolute;
        top: 0;
      }
    }
  }
}
.eventlist {
  .event_cal {
    ul {
      li {
        padding-left: 30px;
        position: relative;
        font-size: 18px;
        height: 28px;
        display: inline-block;
        width: 100%;
      }
    }
  }
}
