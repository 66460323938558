.btn-action{
  cursor: pointer;
}

.table.table-memberlist {
  table-layout: auto;
  tr {
    td {
      padding: 15px 20px;
      border-bottom: 1px solid #e1e2e7;
      font-size: 22px;
      border-top: none;
      vertical-align: middle;
      a.linking {
        color: #333;
        font-size: 20px;
      }
    }
    th {
      padding: 15px 20px;
      vertical-align: top;
      input {
        height: 37px;
        margin-top: 6px;
        border-radius: 3px;
        border: 1px solid #ddd;
        padding: 5px 10px;
      }
    }
  }
}
.csv_row.heading-membership {
  .btn_col.last {
    position: relative;
    right: 0;
    margin-top: 0px !important;
  }
}
.csv_row.heading-membership.membership-responsive {
  .btn_col.box {
    padding-bottom: 20px;
  }
}

.icons_list a {
  color: #c5c6cb;
  text-decoration: none;
  font-size: 24px;
  line-height: 20px;
  margin-right: 19px;
  &:hover {
    color: #222;
  }
}

.csv_row {
  .btn_col.box {
    display: flex;
    align-items: center;
    h2 {
      flex: auto;
      margin-bottom: 0;
      margin-right: 20px;
      box-shadow: none;
    }
    a {
      margin-right: 20px;
      box-shadow: none;
    }
  }
}

.social {
  ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-right: 11px;
      margin-bottom: 0px;
      a {
        background: #c5c6cb;
        color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: inline-block;
        text-align: center;
        padding: 6px 4px;
        font-size: 20px;
      }
    }
  }
}

.selected_list {
  list-style-type: none;
  margin-bottom: 0;
  width: 100%;
  display: inline-block;
  li {
    float: left;
    margin-right: 0px;
    margin-bottom: 20px;
    width: 33.333%;
  }
}

.list_flex {
  .btn-primary {
    display: block;
    margin: 0 auto;
  }
}

button.click_imgbtn {
  border: none;
  background: none;
  img {
    width: 30px;
  }
}

.dropto.dropdown {
  display: inline-block;
}
.dropto.dropdown.show {
  display: inline-block !important;
  min-width: 204px;
  .dropdown-item {
    background: none;
    border: none;
    padding: 10px 15px;
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
}

// .react-datepicker {
//   transform: scale(1.4);
//   -moz-transform: scale(1.4);
//   -webkit-transform: scale(1.4);
// }
.model-content.cent {
  text-align: center;
}

span.cur_ic {
  cursor: pointer;
}

.no-record {
  width: 100%;
  text-align: center;
  position: absolute;
}
.see-more {
  width: 100%;
  text-align: center;
}

.bottom-padd {
  padding-bottom: 18px;
}

// .react-datepicker {
//   display: none;
// }
.react-tabs .panelbody .col1 ul li span.capitalize-text {
  color: #333;
}
.react-tabs .panelbody .no-record {
  position: relative;
}

.selected-filter {
  text-align: left;
  text-transform: capitalize;
}

@media (max-width: 1366px) {
  .csv_row.heading-membership.membership-responsive {
    display: inline-block;
    width: 100%;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last {
    position: relative;
    margin: 15px 0px 0px 0px;
  }
}
@media (max-width: 600px) {
  .csv_row.heading-membership.membership-responsive .btn_col.box {
    display: block;
    text-align: center;
    position: relative;
    width: 100%;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.box a.backarrow {
    float: left;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last {
    text-align: center;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last .btn {
    margin-bottom: 20px !important;
    margin: 0px 10px;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.box h2 {
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .csv_row.heading-membership.membership-responsive .btn {
    padding: 5px 11px;
    font-size: 14px;
    margin: 0px 6px;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last {
    margin: 0px;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last .click_imgbtn {
    padding: 5px 11px;
    font-size: 14px;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last .click_imgbtn img {
    width: 23px;
  }
  .csv_row.heading-membership.membership-responsive .btn_col.last {
    padding-top: 12px;
    display: inline-block;
    // >>>>>>> 1e0efba32437f63ff37b2a4c189ee2fb686a67da
  }
}
