h2.line-heading {
  font-size: 28px;
  border-bottom: 1px solid #c5c6cb;
  padding-bottom: 15px;
  margin-bottom: 35px;
  margin-top: 30px;
}

.payment_info h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.payment_info {
  display: flex;
}
.cardinfo ul {
  padding: 0;
  list-style-type: none;
}

.cardinfo ul li {
  margin-bottom: 11px;
  font-size: 20px;
}

.cardinfo {
  padding: 30px 30px;
}

.cardinfo ul li span {
  font-weight: 600;
}

.cardinfo .panel_bottom {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin: 0 -30px;
  padding: 17px 35px 0;
  font-size: 20px;
}
.cardinfo .panel_bottom span {
  float: right;
}

.cardinfo .panel_bottom span .del {
  color: red;
}
.cardinfo .panel_bottom span a.edit {
  margin-right: 15px;
}
.payment_info .react-tabs__tab--selected {
  border: 2px solid #5595ff;
}
.payment_info .react-tabs__tab-list {
  border: none;
}
.payment_info .react-tabs__tab {
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 185px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 20px;
}

.payment_info .react-tabs__tab i {
  display: block;
  font-size: 28px;
  text-align: center;
}

@media (max-width: 767px) {
  .payment_info {
    display: flex;
    flex-direction: column;
  }
  .tab_bar .react-tabs__tab-list {
    margin-bottom: 20px;
    margin-right: 19px;
    font-size: 16px;
  }
  .payment_info {
    margin: 0px -15px;
  }
  h2.line-heading {
    font-size: 20px;
  }
  .payment_info h3 {
    font-size: 20px;
  }
}

.payment-block{
  .container-inner{
    padding: 40px 15px;
    text-align: center;
  }
}
