.modal-header {
    padding: 1em 1em;
}

.modal-header h5.modal-title {
    font-weight: bold;
    text-transform: uppercase;
}

.modal-footer button{
    padding: 0.4rem 3.5rem;
    font-size: 1rem;
}

.debit-card {
    width: 100%;
    height: 180px;
    padding: 20px;
    background-color: #0093E9;
    background-image: linear-gradient(
160deg, #0093E9 0%, #80D0C7 100%);
    position: relative;
    border-radius: 5px;
    box-shadow: 3px 3px 5px #0000001a;
    transition: all 0.3s ease-in;
    cursor: pointer;
    margin: 10px 0;
}


.debit-card:hover {
    box-shadow: 5px 3px 5px #000000a2
}

.debit-card .input {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #767272;
}

.debit-card .input.default {
    border: 1px solid #ede5e5;
    border-radius: 20px;
    padding: 3px;
    background: #b8deeb;
}

.debit-card .input.default .default-label {
    margin-right: 5px;
}

.debit-card input[type="radio"] {
    width: 20px;
    height: 20px;
    background-color: #eee;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none
}


.card-loader {
    position: absolute;
    background: rgb(253 253 253 / 62%);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-loader img {
    position: initial;
}