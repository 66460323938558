.table_unfix {
  table-layout: auto;
}
a.status {
  border: 1px solid #253547;
  border-radius: 50px;
  padding: 5px 19px;
  color: #253547;
  display: inline-block;
  margin-bottom: unset;
}

a.status.active {
  background: #5595ff;
  color: #fff;
  border-color: #5595ff;
}
a.close {
  display: block;
  background: #1c2d40;
  color: #fff;
  opacity: 1;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  text-align: center;
  padding: 4px;
  font-weight: 200;
}
