input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




// ul.carddetail {
//   display: block;
// }
/* ul.carddetail {
  li {
    width: 33%;
    display: inline-block;
  }

  label span {
    float: none !important;
    padding-left: 4px;
  }
}

.form-group.subs_amount {
  .carddetail li .form-control {
    width: 95%;
    margin: 0px 5px !important;
  }

  .cardnumber i.fa.fa-credit-card {
    top: 55px;
  }
}

.form-group.cardnew-custome {
  .sc-bdVaJa.ixTfPP {
    display: block;
  }
  
  img {
    display: none;
  }

  .input {
    padding: 0px;
  }

  label {
    height: 60px;
    padding: 10px;
    display: inline-block;
    margin: 0px;
    border: 1px solid #ddd;
    margin-right: 10px;
    border-radius: 4px;
    width: 33%;

    input {
      background: transparent;
      height: 38px;
    }
  }

  div#field-wrapper label.sc-bxivhb.kFHeER {
    display: none;
  }

  .hlTfmo::after {
    content: attr(data-max);
    visibility: hidden;
    height: 0;
    display: none;
  }
} */

.form-floating .form-control::placeholder {
  color: transparent !important;
}

input.form-control,
.form-floating label.form-label{
  font-size: 16px;
}

.package {
  padding: 0 1px;

  &.active{
    .package-head{
      background: #5595ff;
      color: #fff;

      .package-price {
        color: #fff;
      }
    } 
  }

  .package-head {
    display: flex;
    position: relative;
    background: #2dcb72;
    color: #fff;    
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
    .package-name {
      font-size: 20px;
      font-weight: bold;
    }

    .package-recommeded{
      position: absolute;
      top: -20px;
      width: 100%;
      color: #000;
      font-size: 14px;
    }

    .package-price {
      color: #4e4040;
      .primary-price{
        font-size: 18px;
        font-weight: bold;
      }

      .dark-area {
        padding: 10px;
        margin-top: 5px;
        background: #2bb969;
        font-size: 12px;
      }

      .annual-price{
        font-size: 14px;
      }
    }
  }

  .package-body {
    .package-row {
      padding: 5px 10px;
      background: #f2f2f2;
      margin: 2px 0;
      text-align: center;
    }
  }

  .package-action {
    background: #dfd9d9;
    padding: 10px;
    min-height: 55px;
    button {
      &.btn{
        margin-top: 0;
        background: #f2f2f2;
        color: #000;
        box-shadow: none;
        border: 1px solid #b9b8b8;
        width: 100%;
        text-align: center;
        display: block;

        &:hover{
          background: #2dcb72;
          color: #fff;
          border: 1px solid #2dcb72;
        }
      }
    }
  }
}

.subscription-period {
  margin-top: 30px;

  h5 {
    font-size: 20px;
    font-weight: bold;
  }

  .btn-group button.btn {
    margin-top: 0;
    color: #000;
    box-shadow: none; 
    
    &.active{
      border: 1px solid #417ee1;
      background: #5495fe;
      color: #fff; 
    }
  }

  .form-check{    
    input.form-check-input {
      display:inline-block;
      cursor: pointer;
    }

    label{
      font-size: inherit;
      &.form-check-label{
        &:before{
          content: none;
        }
      }
    }
  }
}

table.checkout-info{
  tr{
    th, td{
      padding: 0.5rem 0.5rem;
      font-size: 15px;
      text-transform: initial;
      border-width: 0 1px !important;
    }
  }
}

/* .collapse-trigger{
  background: #88f3b7;
  width: 20px;
  height: 20px;
  display: inline-block;
  color: #000;
  border-radius: 50%;
} */

@media (min-width: 576px) {
  .package-head {
    min-height: 120px;
  }
}